<template>
  <div>
    <div style="padding: 20px">
      <div
        class="df"
        style="
          padding: 10px;
          justify-content: space-between;
          background-color: #fff;
          border: 1px solid #ccc;
        "
      >
        <div class="df aic">
          <el-button type="primary" @click="goBack">返回</el-button>
          <el-header style="margin-left: 20px">
            <el-breadcrumb separator-class="el-icon-arrow-right">
              <el-breadcrumb-item>文档管理</el-breadcrumb-item>
              <el-breadcrumb-item>文档列表</el-breadcrumb-item>
              <el-breadcrumb-item>{{
                id === null ? "文章编写" : "文章修改"
              }}</el-breadcrumb-item>
            </el-breadcrumb>
          </el-header>
        </div>
        <el-button type="success" @click="showShareList" v-if="id === null"
          >发布</el-button
        >
        <el-button type="success" @click="edit" v-else>修改</el-button>
      </div>
    </div>

    <div class="df">
      <div style="flex: 1">
        <div style="padding: 20px; padding-top: 0px">
          <div
            style="
              width: 100%;
              background-color: #fff;
              border: 1px solid #ccc;
              padding-top: 20px;
              margin-bottom: 20px;
            "
          >
            <div style="width: 600px">
              <el-form
                :model="formData"
                label-width="80px"
                ref="ruleForm"
                :rules="rules"
              >
                <el-form-item label="标题" prop="title">
                  <el-input v-model="formData.title"></el-input>
                </el-form-item>
                <el-form-item label="摘要" prop="digest">
                  <el-input
                    type="textarea"
                    v-model="formData.digest"
                  ></el-input>
                </el-form-item>
                <el-form-item label="封面" prop="coverImg">
                  <div class="df">
                    <el-upload
                      class="avatar-uploader"
                      action="#"
                      :show-file-list="false"
                      :http-request="uploadSectionFile"
                    >
                      <el-image
                        fit="cover"
                        class="avatar"
                        v-if="formData.coverImg"
                        :src="formData.coverImg"
                      ></el-image>
                      <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                  </div>
                </el-form-item>
                <el-form-item label="标签" prop="tag">
                  <div class="df aic">
                    <template v-for="item in formData.tag">
                      <el-popover
                        placement="top"
                        width="200"
                        trigger="hover"
                        :key="item.id"
                        v-if="item.isDel"
                      >
                        <div
                          class="df aic jcc"
                          style="padding: 10px; color: #bbb"
                        >
                          该标签已被移除
                        </div>
                        <el-tag
                          closable
                          @close="delTag(item)"
                          style="margin-right: 10px"
                          type="danger"
                          :key="item.id"
                          slot="reference"
                          >{{ item.tagName }}</el-tag
                        >
                      </el-popover>
                      <el-tag
                        v-else
                        closable
                        @close="delTag(item)"
                        style="margin-right: 10px"
                        type="info"
                        :key="item.id"
                        >{{ item.tagName }}</el-tag
                      >
                    </template>

                    <!-- <el-popover
                      placement="bottom"
                      width="200"
                      trigger="click"
                      :visible-arrow="false"
                      v-model="showTagList"
                    >
                      <div style="max-height: 300px; overflow-y: scroll">
                        <template v-if="tagComputed.length">
                          <div
                            v-for="item in tagComputed"
                            :key="item.id"
                            class="tag-select-box df aic jcc"
                            @click="addTag(item)"
                          >
                            {{ item.tagName }}
                          </div>
                        </template>
                        <div v-else>
                          <div class="no-data">暂无数据</div>
                        </div>
                      </div>
                      <el-button
                        slot="reference"
                        :style="formData.tag.length ? 'margin-left:10px' : ''"
                        >添加标签</el-button
                      >
                    </el-popover> -->
                    <el-dropdown @command="addTag" placement="bottom">
                      <el-button type="primary"> 添加标签 </el-button>
                      <el-dropdown-menu slot="dropdown">
                        <template v-if="tagComputed.length">
                          <el-dropdown-item
                            v-for="item in tagComputed"
                            :key="item.id"
                            :command="item"
                            >{{ item.tagName }}</el-dropdown-item
                          >
                        </template>
                        <el-dropdown-item disabled v-else
                          >暂无更多标签</el-dropdown-item
                        >
                      </el-dropdown-menu>
                    </el-dropdown>
                    <AddTag style="margin-left: 10px" @addFinish="addFinish" />
                  </div>
                </el-form-item>
                <el-form-item>
                  <div class="df aic">
                    <i style="margin-right: 40px">
                      <el-switch
                        v-model="formData.isComment"
                        active-text="开启评论"
                        inactive-text="关闭评论"
                      >
                      </el-switch>
                    </i>
                    <i>
                      <el-switch
                        v-model="formData.recommend"
                        active-text="首页推荐"
                        inactive-text="无"
                        active-color="#13ce66"
                      >
                      </el-switch>
                    </i>
                  </div>
                </el-form-item>
              </el-form>
            </div>
          </div>
          <div style="border: 1px solid #ccc">
            <Toolbar
              style="border-bottom: 1px solid #ccc"
              :editor="editor"
              :defaultConfig="toolbarConfig"
              :mode="mode"
            />
            <Editor
              class="preview-box"
              style="height: 500px; overflow-y: hidden"
              v-model="html"
              :defaultConfig="editorConfig"
              :mode="mode"
              @onCreated="onCreated"
            />
          </div>
        </div>
      </div>
      <div style="padding: 0px 20px 20px 0px; padding-left: 0">
        <div
          style="
            width: 375px;
            min-height: 667px;
            background-color: #fff;
            border: 1px solid #ccc;
            padding: 15px;
            overflow: hidden;
            max-height: 1060px;
            overflow-y: scroll;
          "
        >
          <div
            class="df aic"
            style="font-size: 24px; font-weight: 600; padding: 10px"
          >
            {{ formData.title }}
          </div>
          <div
            style="
              color: #bbb;
              text-align: left;
              padding: 0px 15px;
              border: 1px solid #0081ff;
              padding: 15px;
              margin-bottom: 15px;
            "
            class="df aic"
            v-if="formData.digest"
          >
            {{ formData.digest }}
          </div>
          <div v-html="html" style="" class="preview-box"></div>
        </div>
      </div>
    </div>
    <el-dialog :visible.sync="showShareCompany" width="50%" title="分享企业">
      <div
        style="width: 100%; padding: 20px; padding-top: 0"
        v-if="shareCompanyList.length"
      >
        <div class="df">已选择不分享公司</div>
        <div class="df">
          <el-tag
            style="margin-right: 10px; margin-bottom: 10px"
            v-for="tag in shareCompanyList"
            :key="tag.companyId"
            closable
            type="info"
            @close="handleShareCompany(tag)"
          >
            {{ tag.companyName }}
          </el-tag>
        </div>
      </div>
      <el-table
        border
        :data="shareCompany"
        style="width: 100%"
        v-if="shareCompany.length || !shareCompanyTotal"
      >
        <el-table-column type="index" label="序号" width="55">
        </el-table-column>
        <el-table-column prop="companyName" label="企业名称" width="180">
        </el-table-column>
        <el-table-column type="isAudit" label="是否分享" width="180">
          <template slot-scope="scope">
            <el-button
              type="text"
              class="disib green"
              size="mini"
              style="margin-left: 10px"
              v-if="!shareCompanyList.includes(scope.row)"
              @click="handleShareCompany(scope.row)"
              >取消分享</el-button
            >
            <el-button
              type="text"
              class="disib green"
              size="mini"
              style="margin-left: 10px"
              v-else
              @click="handleShareCompany(scope.row)"
              >分享</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="df" style="justify-content: flex-end">
        <el-pagination
          background
          :page-size="10"
          layout="total,prev, pager, next"
          :total="shareCompanyTotal"
          :current-page="shareCompanyQuery.page"
          @current-change="companyPageChange"
        >
        </el-pagination>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showShareCompany = false;shareCompanyList=[]">取 消</el-button>
        <el-button type="primary" @click="submit"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import axios from "../../../util/api";
import api from "../../../util/extra-api";
import { local } from "@/util/util";
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
import "@wangeditor/editor/dist/css/style.css";

import AddTag from "./components/AddTag.vue";
export default {
  components: { Editor, Toolbar, AddTag },
  data() {
    var validateTag = (rule, value, callback) => {
      if (value.length === 0) {
        callback(new Error("请选择标签"));
      } else {
        callback();
      }
    };
    return {
      shareCompanyList: [],
      showShareCompany: false,
      shareCompany: [],
      shareCompanyQuery: {
        page: 1,
        limit: 10,
        companyId: null,
        type: 1,
      },
      shareCompanyTotal: 0,
      editor: null,
      html: "",
      toolbarConfig: {
        toolbarKeys: [
          "headerSelect",
          "blockquote",
          "|",
          "bold",
          "underline",
          "italic",
          {
            key: "group-more-style",
            title: "更多",
            iconSvg:
              '<svg viewBox="0 0 1024 1024"><path d="M204.8 505.6m-76.8 0a76.8 76.8 0 1 0 153.6 0 76.8 76.8 0 1 0-153.6 0Z"></path><path d="M505.6 505.6m-76.8 0a76.8 76.8 0 1 0 153.6 0 76.8 76.8 0 1 0-153.6 0Z"></path><path d="M806.4 505.6m-76.8 0a76.8 76.8 0 1 0 153.6 0 76.8 76.8 0 1 0-153.6 0Z"></path></svg>',
            menuKeys: ["through", "sup", "sub", "clearStyle"],
          },
          "color",
          "bgColor",
          "|",
          "fontSize",
          "fontFamily",
          "lineHeight",
          "|",
          "bulletedList",
          "numberedList",
          {
            key: "group-justify",
            title: "对齐",
            iconSvg:
              '<svg viewBox="0 0 1024 1024"><path d="M768 793.6v102.4H51.2v-102.4h716.8z m204.8-230.4v102.4H51.2v-102.4h921.6z m-204.8-230.4v102.4H51.2v-102.4h716.8zM972.8 102.4v102.4H51.2V102.4h921.6z"></path></svg>',
            menuKeys: [
              "justifyLeft",
              "justifyRight",
              "justifyCenter",
              "justifyJustify",
            ],
          },
          {
            key: "group-indent",
            title: "缩进",
            iconSvg:
              '<svg viewBox="0 0 1024 1024"><path d="M0 64h1024v128H0z m384 192h640v128H384z m0 192h640v128H384z m0 192h640v128H384zM0 832h1024v128H0z m0-128V320l256 192z"></path></svg>',
            menuKeys: ["indent", "delIndent"],
          },
          "|",
          // "insertLink",
          {
            key: "group-image",
            title: "图片",
            iconSvg:
              '<svg viewBox="0 0 1024 1024"><path d="M959.877 128l0.123 0.123v767.775l-0.123 0.122H64.102l-0.122-0.122V128.123l0.122-0.123h895.775zM960 64H64C28.795 64 0 92.795 0 128v768c0 35.205 28.795 64 64 64h896c35.205 0 64-28.795 64-64V128c0-35.205-28.795-64-64-64zM832 288.01c0 53.023-42.988 96.01-96.01 96.01s-96.01-42.987-96.01-96.01S682.967 192 735.99 192 832 234.988 832 288.01zM896 832H128V704l224.01-384 256 320h64l224.01-192z"></path></svg>',
            menuKeys: ["insertImage", "uploadImage"],
          },
          {
            key: "group-video",
            title: "视频",
            iconSvg:
              '<svg viewBox="0 0 1024 1024"><path d="M981.184 160.096C837.568 139.456 678.848 128 512 128S186.432 139.456 42.816 160.096C15.296 267.808 0 386.848 0 512s15.264 244.16 42.816 351.904C186.464 884.544 345.152 896 512 896s325.568-11.456 469.184-32.096C1008.704 756.192 1024 637.152 1024 512s-15.264-244.16-42.816-351.904zM384 704V320l320 192-320 192z"></path></svg>',
            menuKeys: ["insertVideo", "uploadVideo"],
          },
          "divider",
          "|",
          "undo",
          "redo",
        ],
      },
      editorConfig: {
        placeholder: "请输入正文...",
        MENU_CONF: {
          uploadImage: {
            async customUpload(file, insertFn) {
              const fileType = file.type,
                isImage = fileType.indexOf("image") != -1,
                isLt10M = file.size / 1024 / 1024 < 10;

              // 这里常规检验，看项目需求而定
              if (!isImage) {
                this.$message.error("只能上传图片格式png、jpg、gif!");
                return;
              }
              if (!isLt10M) {
                this.$message.error("只能上传图片大小小于10M");
                return;
              }
              const fileFormData = new FormData();
              // fileFormData.append("file", file);
              fileFormData.append("file", file);
              fileFormData.append(
                "company_id",
                localStorage.getItem("company_id")
              );
              fileFormData.append(
                "user_id",
                local.get("user") && local.get("user").user_id
              );
              let resp = await axios.uploadFile(
                "/pc/file/common",
                fileFormData
              );
              // .then((resp) => {});
              if (resp.code != 200) {
                this.$message.error("图片上传失败，请重新上传");
              } else {
                console.log(resp);
                insertFn(resp.url, "", resp.url);
              }
            },
          },
          uploadVideo: {
            async customUpload(file, insertFn) {
              const fileType = file.type,
                isVidoe = fileType.includes("video");
              if (!isVidoe) {
                this.$message.error("只能上传视频格式!");
                return false;
              }
              const fileFormData = new FormData();
              // fileFormData.append("file", file);
              fileFormData.append("file", file);
              fileFormData.append(
                "company_id",
                localStorage.getItem("company_id")
              );
              fileFormData.append(
                "user_id",
                local.get("user") && local.get("user").user_id
              );
              let resp = await axios.uploadFile(
                "/pc/file/common",
                fileFormData
              );
              // .then((resp) => {});
              if (resp.code != 200) {
                this.$message.error("视频上传失败，请重新上传");
              } else {
                console.log(resp);
                insertFn(resp.url, "", resp.url);
              }
            },
          },
        },
      },
      mode: "simple", // or 'default'
      formData: {
        title: "",
        digest: "",
        isComment: true,
        recommend: false,
        coverImg: "",
        tag: [],
        originalData: null,
      },
      companyId: null,
      tagList: [],
      showTagList: false,
      rules: {
        title: [{ required: true, message: "请输入标题", trigger: "blur" }],
        digest: [{ required: true, message: "请输入摘要", trigger: "blur" }],
        coverImg: [{ required: true, message: "请上传封面", trigger: "blur" }],
        tag: [{ validator: validateTag, trigger: "blur" }],
      },
      id: null,
    };
  },
  computed: {
    tagComputed() {
      return this.tagList.filter((v) => !this.formData.tag.includes(v));
    },
  },
  methods: {
    handleShareCompany(row) {
      if (this.shareCompanyList.includes(row)) {
        this.shareCompanyList = this.shareCompanyList.filter((v) => v != row);
      } else {
        this.shareCompanyList.push(row);
      }
    },
    showShareList() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          this.getshareCompany();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    getshareCompany() {
      if (!this.shareCompanyQuery.companyId) {
        this.shareCompanyQuery.companyId = local.get("company_id");
      }

      let data = {
        ...this.shareCompanyQuery,
      };
      data.typeId = "";
      this.formData.tag.forEach((v, i) => {
        data.typeId += v.id;
        if (i !== this.formData.tag.length - 1) {
          data.typeId += ",";
        }
      });
      api.get("/v1/wx/article/pc/companyList", data).then((res) => {
        if (res.code === 200) {
          this.shareCompany = res.data;
          this.shareCompanyTotal = res.total;
          if (res.total) {
            if (!this.showShareCompany) {
              this.showShareCompany = true;
            }
          } else {
          }
        }
      });
    },
    companyPageChange(page) {
      this.shareCompanyQuery.page = page;
      this.getshareCompany();
    },
    goBack() {
      this.$router.back();
    },
    submit() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          let data = {
            ...this.formData,
            companyId: this.companyId,
            sort: 0,
            content: this.html,
          };
          data.typeId = "";
          data.tag.forEach((v, i) => {
            data.typeId += v.id;
            if (i !== data.tag.length - 1) {
              data.typeId += ",";
            }
          });
          if(this.shareCompanyList.length){
            data.noShareCompany=this.shareCompanyList.map(v=>v.companyId)
          }else{
            data.noShareCompany=[]
          }
          api.post("/v1/wx/article/pc/publish", data).then((res) => {
            if (res.code == 200) {
              this.goBack();
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    edit() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          let data = {
            ...this.formData,
            companyId: this.companyId,
            sort: 0,
            content: this.html,
            id: this.id,
          };
          data.typeId = "";
          data.tag.forEach((v, i) => {
            data.typeId += v.id;
            if (i !== data.tag.length - 1) {
              data.typeId += ",";
            }
          });
          api.post("/v1/wx/article/pc/updateArticle", data).then((res) => {
            if (res.code == 200) {
              this.goBack();
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    addFinish() {
      this.getTag();
    },
    onCreated(editor) {
      this.editor = Object.seal(editor); // 一定要用 Object.seal() ，否则会报错
    },
    getTag() {
      api
        .get("/v1/wx/article/pc/selectTag", {
          companyId: this.companyId,
        })
        .then((res) => {
          if (res.code == 200) {
            this.tagList = res.data;
          }
        });
    },
    addTag(data) {
      // console.log(data);
      this.formData.tag.push(data);
      this.showTagList = false;
      this.$refs["ruleForm"].validateField("tag");
    },
    delTag(data) {
      this.formData.tag = this.formData.tag.filter((v) => v !== data);
    },
    uploadSectionFile(params) {
      const file = params.file,
        fileType = file.type,
        isImage = fileType.indexOf("image") != -1,
        isLt10M = file.size / 1024 / 1024 < 10;

      // 这里常规检验，看项目需求而定
      if (!isImage) {
        this.$message.error("只能上传图片格式png、jpg、gif!");
        return;
      }
      if (!isLt10M) {
        this.$message.error("只能上传图片大小小于10M");
        return;
      }
      const fileFormData = new FormData();
      // fileFormData.append("file", file);
      fileFormData.append("file", file);
      fileFormData.append("company_id", this.companyId);
      fileFormData.append(
        "user_id",
        local.get("user") && local.get("user").user_id
      );
      axios.uploadFile("/pc/file/common", fileFormData).then((resp) => {
        if (resp.code != 200) {
          this.$message.error("图片上传失败，请重新上传");
        } else {
          this.formData.coverImg = resp.url;
          this.$refs["ruleForm"].validateField("coverImg");
        }
      });
    },
  },
  mounted() {
    this.companyId = localStorage.getItem("company_id");
    this.getTag();
    if (this.$route.query.id) {
      this.id = Number(this.$route.query.id);
      api
        .get("/v1/wx/article/pc/articleDetail", { id: this.$route.query.id })
        .then((res) => {
          if (res.code === 200) {
            console.log(res.data);
            this.formData.coverImg = res.data.coverImg;
            this.formData.title = res.data.title;
            this.formData.digest = res.data.digest;
            this.formData.isComment = res.data.isComment;
            this.formData.recommend = res.data.recommend;
            this.html = res.data.content;
            res.data.typeId.split(",").forEach((v, i) => {
              let data = this.tagList.filter((item) => item.id == v);
              if (data.length) {
                this.formData.tag.push(data[0]);
              } else {
                this.formData.tag.push({
                  tagName: res.data.tagNames[i],
                  id: Number(v),
                  isDel: true,
                });
              }
            });
          }
        });
    }
  },
  beforeDestroy() {
    const editor = this.editor;
    if (editor == null) return;
    editor.destroy(); // 组件销毁时，及时销毁编辑器
  },
};
</script>


<style lang="scss" scoped>
/deep/.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
/deep/.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
/deep/.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 418px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 418px;
  height: 178px;
  display: block;
}
.no-data {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  color: #bbb;
}
.tag-select-box {
  font-size: 18px;
  cursor: pointer;
  margin: 4px 0;
  padding: 4px 10px;
}
.tag-select-box:hover {
  background-color: #409eff;
  color: #fff;
}

/deep/.w-e-text-container {
  b {
    font-weight: bold !important;
  }
  i {
    font-style: italic !important;
  }
}
/deep/ .el-header {
  height: auto !important;
}
.preview-box {
  /deep/strong {
    font-weight: bold !important;
  }
  /deep/ em {
    font-style: italic !important;
  }
  /deep/ol li {
    list-style-type: decimal !important;
    list-style-position: inside !important;
  }
  /deep/ul li {
    list-style-type: disc !important;
    list-style-position: inside !important;
  }
}
</style>